<template>
  <div class="devices schedule">
    <div class="sub-wrap">
      <div class="container">
        <form @submit="update_schedule" class="form" method="post">
          <div class="header-wrap cf">
            <div class="header-left">
              <h2>{{ schedule.name }}</h2>
            </div>

            <div class="header-right desktop-only">
              <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round" />
            </div>
          </div>

          <devices-nav></devices-nav>

          <div v-show="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>

          <div v-show="!loading">
            <div class="content-wrap">
              <header class="content-header">
                <h3>{{ $t("schedule.manage") }}</h3>
                <p class="header-action">
                  <a @click="createSlotFromModal" class="button button-ci button-round button-small">
                    <img src="../../assets/icons/plus.svg" />
                    <span>{{ $t("schedule.addSlot") }}</span>
                  </a>
                </p>
              </header>

              <div class="content-body">
                <div>
                  <div class="form-wrap" style="margin-bottom: 0">
                    <label for="name">{{ $t("global.name") }}</label>
                    <input v-model="schedule.name" class="form-input" type="name" name="name" id="name" />
                  </div>

                  <FullCalendar
                    ref="fullCalendar"
                    :options="calendar_options"
                    :events="slots"
                    selectable="true"
                    @select="new_slot"
                    @eventClick="show_slot"
                    defaultView="timeGridWeek"
                    header="false"
                    :firstDay="1"
                    :allDaySlot="false"
                    :columnHeaderFormat="{ weekday: 'short' }"
                    :displayEventTime="true"
                    :plugins="calendarPlugins"
                    locale="de"
                  />

                  <p style="margin-bottom: 0; text-align: right">
                    <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round button-100" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <modal name="create-slot" height="auto">
      <div class="dialog-content">
        <div class="dialog-c-title">{{ $t("schedule.createSlot") }}</div>
        <div class="dialog-c-text">
          <strong>{{ $t("schedule.timeframe") }}:</strong>

          <!-- Show input from calendar selection -->
          <div v-show="modal_temp.start">
            <p style="margin-top: 0">{{ modal_temp.start }} – {{ modal_temp.end }}</p>
          </div>

          <!-- Else show input fields -->
          <div v-show="!modal_temp.start" class="time-selector">
            <div class="manuell">
              <div class="select-wrapper">
                <em>{{ $t("global.weekday") }}</em>
                <select v-model="modal.new_day" id="weekday" required>
                  <option value="1">{{ $t("global.monday") }}</option>
                  <option value="2">{{ $t("global.thuesday") }}</option>
                  <option value="3">{{ $t("global.wednesday") }}</option>
                  <option value="4">{{ $t("global.thursday") }}</option>
                  <option value="5">{{ $t("global.friday") }}</option>
                  <option value="6">{{ $t("global.saturday") }}</option>
                  <option value="0">{{ $t("global.sunday") }}</option>
                </select>
              </div>
              <div class="time-input-wrapper">
                <em>{{ $t("global.from") }}: </em><input id="starttime" type="time" min="00:00" step="1800" name="start" v-model="modal.new_start" required />
                <em>{{ $t("global.to") }}: </em><input id="endtime" type="time" min="00:00" step="1800" name="end" v-model="modal.new_end" required />
              </div>
            </div>
            <div class="week">
              <input id="week" type="checkbox" v-model="modal.week" @change="weekCheckbox()" />
              <label for="week" style="margin-left: 5px">{{ $t("schedule.week") }}</label>
            </div>
          </div>
          <br />
          <strong>{{ $t("schedule.type") }}:</strong>
          <br />
          <div class="ad-input-wrapper">
            <div class="radio-wrap">
              <div class="r-s">
                <input type="radio" id="sales" value="sales" v-model="modal.slot_type" />
                <label for="sales">{{ $t("global.adExternal") }}</label>
              </div>
              <div class="r-s">
                <input type="radio" id="internal" value="internal" v-model="modal.slot_type" :disabled="playlists.length > 0 ? false : true" />
                <label for="internal">{{ $t("global.adInternal") }}</label>
              </div>
            </div>

            <div v-if="modal.slot_type == 'internal'" class="select-wrapper">
              <em>{{ $t("schedule.choose") }}:</em>
              <select v-model="modal.playlist_uuid">
                <option v-for="playlist in playlists" v-bind:key="playlist.uuid" v-bind:value="playlist.uuid">
                  {{ playlist.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="dialog-c-buttons">
          <div class="row">
            <div class="col-12">
              <a @click="create_slot" class="button button-ci button-small button-100">{{ $t("schedule.createSlot") }}</a>
            </div>
            <div class="col-12">
              <a @click="hide_slot" class="button button-grey button-small button-100">{{ $t("global.close") }}</a>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="show-slot">
      <div class="dialog-content">
        <div class="dialog-c-title">{{ $t("global.slot") }} {{ selected_slot.title }}</div>
        <div class="dialog-c-text">
          <div v-if="selected_slot.title !== '1-' + this.$t('global.adExternal')" class="select-wrapper">
            <em>{{ $t("schedule.choose") }}:</em>

            <select v-model="selected_slot.playlist_uuid">
              <option
                v-for="playlist in playlists"
                v-bind:key="playlist.uuid"
                v-bind:value="playlist.uuid"
                :selected="playlist.uuid == selected_slot.playlist_uuid"
              >
                {{ playlist.name }}
              </option>
            </select>
          </div>

          <p v-if="selected_slot.title === '1-' + this.$t('global.adExternal')">{{ $t("schedule.deleteSlot") }}</p>

          <div v-show="this.slots.length === 1">
            <p class="alert alert-info">{{ $t("schedule.slotError") }}</p>
          </div>
        </div>
        <div class="dialog-c-buttons">
          <div class="row">
            <div class="col-12">
              <a @click="delete_slot" class="button button-red button-small button-100">{{ $t("schedule.slotDelete") }}</a>
            </div>
            <div class="col-12">
              <a @click="save_modal" v-if="selected_slot.title !== '1-' + this.$t('global.adExternal')" class="button button-grey button-small button-100">{{
                $t("global.change")
              }}</a>
              <a
                @click="$modal.hide('show-slot')"
                v-if="selected_slot.title === '1-' + this.$t('global.adExternal')"
                class="button button-grey button-small button-100"
                >{{ $t("global.close") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="delete-slot">
      <div class="dialog-content">
        <div class="dialog-c-title">{{ $t("schedule.slotDelete") }}</div>
        <div class="dialog-c-text"></div>
        <div class="dialog-c-buttons">
          <div class="row">
            <div class="col-12">
              <a @click="delete_slot" class="button button-ci button-small button-100">{{ $t("schedule.slotDelete") }}</a>
            </div>
            <div class="col-12">
              <a @click="$modal.hide('delete-slot')" class="button button-grey button-small button-100">{{ $t("global.close") }}</a>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";
import DevicesNav from "../../components/DevicesNav.vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import moment from "moment";

import "@fullcalendar/timegrid/main.css";

export default {
  name: "schedule",
  components: {
    FullCalendar,
    DevicesNav,
  },
  data() {
    return {
      calendar_options: {},
      loading: true,
      schedule: {},
      slots: [],
      calendarPlugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
      events: [],
      playlists: [],
      selected_slot: {
        title: "",
        id: "",
        playlist_uuid: "",
      },
      modal: {
        start_day: "",
        duration: "",
        slot_type: "",
        playlist_uuid: "",
        new_day: moment().weekday(),
        new_start: "00:00",
        new_end: "23:59",
        week: false,
      },
      modal_temp: {
        start: "",
        end: "",
      },
      delete_modal: {},
    };
  },
  methods: {
    get_schedule() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/schedules/" + this.$route.params.uuid, { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          this.schedule = response.data.schedule;

          var this_n = this;
          response.data.schedule.slots.forEach(function (item) {
            var slot_name = "";
            var bg_color = "";
            var txt_color = "";

            if (item.slot_type == "internal") {
              slot_name = item.playlist_name;
              bg_color = "#444";
              txt_color = "#fff";

              this_n.slots.push({
                id: item.id,
                title: slot_name,
                start_day: item.start_day,
                daysOfWeek: [item.start_day],
                startTime: item.start_time,
                duration_real: item.duration,
                duration: item.duration * 1000 * 60,
                backgroundColor: bg_color,
                color: bg_color,
                textColor: txt_color,
                playlist_uuid: item.playlist_uuid,
                slot_type: item.slot_type,
              });
            } else {
              slot_name = this_n.$t("global.adExternal");
              bg_color = this_n.$themeColor;
              txt_color = this_n.$themeContrastColor;

              this_n.slots.push({
                id: item.id,
                title: slot_name,
                start_day: item.start_day,
                daysOfWeek: [item.start_day],
                startTime: item.start_time,
                duration_real: item.duration,
                duration: item.duration * 1000 * 60,
                backgroundColor: bg_color,
                color: bg_color,
                textColor: txt_color,
                slot_type: item.slot_type,
              });
            }
          });
        });
    },
    get_playlists() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/playlists", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.loading = false;
          this.playlists = response.data.playlists;
        });
    },
    new_slot(details) {
      this.modal_temp.start = moment(details.start).locale("de").format("dddd HH:mm");
      this.modal_temp.end = moment(details.end).locale("de").format("dddd HH:mm");
      this.modal.start = details.start;
      this.modal.duration = (details.end - details.start) / 1000 / 60;
      this.modal.playlist_uuid = this.playlists[0].uuid;
      this.$modal.show("create-slot");
    },
    create_slot() {
      var finalStart, finalDay, finalTime, finalDuration, finalDurationReal;

      if (this.modal.start) {
        /* if choosen from calender */
        finalStart = moment(this.modal.start).format("d");
        finalDay = [moment(this.modal.start).format("d")];
        finalTime = moment(this.modal.start).format("HH:mm");
        finalDuration = this.modal.duration * 1000 * 60;
        finalDurationReal = this.modal.duration;

        // fix if more than a week
        if (finalDuration > 604800000 && finalDurationReal > 10080) {
          finalDuration = 604800000;
          finalDurationReal = 10080;
        }
      } else {
        /* choosen from inputs */
        var duration = (moment(this.modal.new_end, "HH:mm") - moment(this.modal.new_start, "HH:mm")) / 1000 / 60;
        finalStart = this.modal.new_day;
        finalDay = [this.modal.new_day];
        finalTime = this.modal.new_start;
        finalDuration = duration * 1000 * 60;
        finalDurationReal = duration;

        if (this.modal.week) {
          finalStart = 1;
          finalDuration = 604800000;
          finalDurationReal = 10080;
          finalTime = "00:00";
        }
      }

      var slot_name, bg_color, txt_color;
      if (this.modal.slot_type == "internal") {
        slot_name = "";
        this.playlists.forEach((playlist) => {
          if (this.modal.playlist_uuid == playlist.uuid) {
            slot_name = playlist.name;
          }
        });
        bg_color = "#444";
        txt_color = "#fff";

        this.slots.push({
          title: slot_name,
          start_day: finalStart,
          daysOfWeek: finalDay,
          startTime: finalTime,
          duration: finalDuration,
          duration_real: finalDurationReal,
          backgroundColor: bg_color,
          color: bg_color,
          textColor: txt_color,
          playlist_uuid: this.modal.playlist_uuid,
          slot_type: this.modal.slot_type,
        });
      } else {
        slot_name = "1-" + this.$t("global.adExternal");
        bg_color = this.$themeColor;
        txt_color = this.$themeContrastColor;

        this.slots.push({
          title: slot_name,
          start_day: finalStart,
          daysOfWeek: finalDay,
          startTime: finalTime,
          duration: finalDuration,
          duration_real: finalDurationReal,
          backgroundColor: bg_color,
          color: bg_color,
          textColor: txt_color,
          slot_type: this.modal.slot_type,
        });
      }

      this.modal = {
        start: "",
        start_day: "",
        duration: "",
        slot_type: "",
        playlist_uuid: "",
        new_day: "",
        new_start: "",
        new_end: "",
        week: false,
      };

      this.modal_temp = {
        start: "",
        end: "",
      };

      this.$modal.hide("create-slot");
    },
    show_slot(details) {
      this.selected_slot.id = details.event.id;
      this.selected_slot.title = details.event.title;

      this.slots
        .filter((slot) => slot.id == this.selected_slot.id)
        .forEach((slot) => {
          this.selected_slot.playlist_uuid = slot.playlist_uuid;
        });

      this.$modal.show("show-slot");
    },
    hide_slot() {
      this.modal = {
        start_day: "",
        duration: "",
        slot_type: "",
        playlist_uuid: "",
        new_day: "",
        new_start: "",
        new_end: "",
      };

      this.modal_temp = {
        start: "",
        end: "",
      };

      this.$modal.hide("create-slot");
    },
    createSlotFromModal() {
      this.$modal.show("create-slot");
    },

    update_schedule: function (e) {
      e.preventDefault();

      var slots = [];

      this.slots.forEach(function (slot) {
        if (slot.slot_type == "internal") {
          slots.push({
            start_day: slot.start_day,
            start_time: slot.startTime,
            duration: slot.duration_real,
            playlist_uuid: slot.playlist_uuid,
            slot_type: slot.slot_type,
          });
        } else {
          slots.push({
            start_day: slot.start_day,
            start_time: slot.startTime,
            duration: slot.duration_real,
            slot_type: slot.slot_type,
          });
        }
      });

      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/schedules/" + this.$route.params.uuid,
          {
            name: this.schedule.name,
            slots: slots,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("schedule.created"),
          });
          this.$router.push("/schedules");
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    delete_slot() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.getEventById(this.selected_slot.id).remove();

      this.slots.forEach((slot, index) => {
        if (slot.id == this.selected_slot.id) {
          this.slots.splice(index, 1);
        }
      });
      this.$modal.hide("show-slot");
    },
    save_modal() {
      this.updatePlaylist();
      this.$modal.hide("show-slot");
    },
    updatePlaylist() {
      let newName = "New Playlist";
      this.playlists.forEach((playlist) => {
        if (this.selected_slot.playlist_uuid == playlist.uuid) {
          newName = playlist.name;
        }
      });

      this.slots
        .filter((slot) => slot.id == this.selected_slot.id)
        .forEach((slot) => {
          slot.title = newName;
          slot.playlist_uuid = this.selected_slot.playlist_uuid;
        });
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;

      if (window.innerWidth < 600 && this.$refs.fullCalendar) {
        this.$refs.fullCalendar.getApi().changeView("listWeek");
      } else {
        this.$refs.fullCalendar.getApi().changeView("timeGridWeek");
      }
    },
    weekCheckbox() {
      if (this.modal.week) {
        document.querySelector("#weekday").disabled = true;
        document.querySelector("#starttime").disabled = true;
        document.querySelector("#endtime").disabled = true;
      } else {
        document.querySelector("#weekday").disabled = false;
        document.querySelector("#starttime").disabled = false;
        document.querySelector("#endtime").disabled = false;
      }
    },
  },
  mounted() {
    this.get_schedule();
    this.get_playlists();

    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style lang="scss">
/* hide calendar list view date */
.fc-list-heading-alt {
  display: none;
}

.fc-scroller {
  height: auto !important;
}

.select-wrapper select {
  height: 30px;
  width: 110px;
  padding-left: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 7px;
}

.time-input-wrapper input {
  height: 30px;
  width: 80px;
  margin-left: 7px;
  margin-right: 12px;
}

.radio-wrap {
  padding-right: 15px;

  input {
    width: 14px;
    height: 14px;
  }

  .r-s {
    margin-bottom: 5px;
  }
}

.ad-input-wrapper {
  margin-top: 10px;
  .select-wrapper {
    margin-left: 18px;
    select {
      margin-top: 0;
      width: 140px;
    }
  }
}

@media (min-width: 768px) {
  .time-selector {
    display: flex;
  }

  .manuell {
    border-right: 1px solid gray;
    margin-right: 20px;
    padding-right: 20px;
  }

  .week {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .manuell {
    border-bottom: 1px solid gray;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
</style>
